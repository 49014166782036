var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加限定条件",
            center: "",
            visible: _vm.showproduct,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showproduct = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { staticStyle: { height: "300px", "overflow-y": "auto" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("p", [_vm._v("渠道")]),
                      _c("el-tree", {
                        ref: "area",
                        attrs: {
                          "node-key": "id",
                          data: _vm.arealist,
                          "set-checked-keys": _vm.form.area_data,
                          "show-checkbox": "",
                          props: _vm.defaultProps,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("p", [_vm._v("区域")]),
                      _c("el-tree", {
                        ref: "channel",
                        attrs: {
                          "node-key": "id",
                          data: _vm.channellist,
                          "set-checked-keys": _vm.form.channel_data,
                          "show-checkbox": "",
                          props: _vm.channeProps,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("p", [_vm._v("等级")]),
                      _c("el-tree", {
                        ref: "grade",
                        attrs: {
                          "node-key": "id",
                          data: _vm.gradelist,
                          "set-checked-keys": _vm.form.class_data,
                          "show-checkbox": "",
                          props: _vm.gradeProps,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showproduct = !_vm.showproduct
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }