<template>
  <div>
    <el-dialog title="添加限定条件" center :visible.sync="showproduct">
      <div>
        <el-row style="height: 300px; overflow-y: auto">
          <el-col :span="8">
            <p>渠道</p>
            <el-tree
              ref="area"
              node-key="id"
              :data="arealist"
              :set-checked-keys="form.area_data"
              show-checkbox
              :props="defaultProps"
            ></el-tree>
          </el-col>
          <el-col :span="8">
            <p>区域</p>
            <el-tree
              ref="channel"
              node-key="id"
              :data="channellist"
              :set-checked-keys="form.channel_data"
              show-checkbox
              :props="channeProps"
            ></el-tree>
          </el-col>
          <el-col :span="8">
            <p>等级</p>
            <el-tree
              ref="grade"
              node-key="id"
              :data="gradelist"
              :set-checked-keys="form.class_data"
              show-checkbox
              :props="gradeProps"
            ></el-tree>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保存</el-button>
        <el-button @click="showproduct = !showproduct">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Addproduct',
    data() {
      return {
        showproduct: false,
        defaultProps: {
          children: 'children',
          label: 'area_name',
        },
        channeProps: {
          children: 'children',
          label: 'channel_name',
        },
        gradeProps: {
          children: 'children',
          label: 'class_name',
        },
        url: {
          dealer: '/baseAdmin/dealer-area/index',
          customer: '/baseAdmin/customer-channel/index',
          grade: '/baseAdmin/customer-class/index',
          save: '/shopAdmin/goods-limit/submit',
        },
        gradelist: [], //客户等级
        channellist: [], //渠道
        arealist: [], //区域
        form: {
          id: '', //限定id（修改用，新增不用）,
          goods_data: [], //限定商品id，数组形式(商品限定和其他限定不一起提交),
          area_data: [], //限定区域iid，数组形式,
          area_all: '', //区域是否全选1是0否,
          channel_data: [], //限定渠道id,数组形式,
          channel_all: '', //渠道是否全选1是0否,
          class_data: [], //等级id,数组形式,
          class_all: '', //等级全选1是0否,
        },
      }
    },
    watch: {
      showproduct(val) {
        if (!val) {
          this.$refs.area.setCheckedKeys([])
          this.$refs.channel.setCheckedKeys([])
          this.$refs.grade.setCheckedKeys([])
          this.form = {
            id: '', //限定id（修改用，新增不用）,
            goods_data: [], //限定商品id，数组形式(商品限定和其他限定不一起提交),
            area_data: [], //限定区域iid，数组形式,
            area_all: '', //区域是否全选1是0否,
            channel_data: [], //限定渠道id,数组形式,
            channel_all: '', //渠道是否全选1是0否,
            class_data: [], //等级id,数组形式,
            class_all: '', //等级全选1是0否,
          }
        }
      },
    },
    mounted() {
      this.handlergrade(), this.handlerchannel(), this.handlerarea()
    },
    methods: {
      handlerinfodata(data) {
        console.log(data)
      },
      handlersave() {
        this.form.area_data = this.$refs.area.getCheckedKeys()
        this.form.channel_data = this.$refs.channel.getCheckedKeys()
        this.form.class_data = this.$refs.grade.getCheckedKeys()
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log(res)
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.showproduct = false
            this.$emit('getlist')
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerarea() {
        postAction(this.url.dealer).then((res) => {
          console.log(res)
          this.arealist = res.data
        })
      },
      handlerchannel() {
        postAction(this.url.customer).then((res) => {
          console.log(res)
          this.channellist = res.data
        })
      },
      handlergrade() {
        postAction(this.url.grade).then((res) => {
          console.log(res)
          this.gradelist = res.data
        })
      },
    },
  }
</script>

<style scoped></style>
